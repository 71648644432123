.timeline {
  .vertical-timeline-element-title {
    font-size: 16px;

    & + div {
      font-size: 14px;
    }
  }

  background-color: #F8F8F9;
  padding: 0 20px;
  border-radius: 4px;
  //height: 75vh;
  //overflow-y: auto;

  .vertical-timeline {
    width: 97%;
  }

  .vertical-timeline-element {
    margin: 2em 0;
  }

  .vertical-timeline-element-content {
    padding: .5em 1em;
    box-shadow: none;
  }
}

.additionalTab {
  .asc {
    position: relative;
    top: 5px;
  }

  .desc {
    position: relative;
    top: -5px;
    transform: rotate(180deg);
  }

  .dynamicFormTable {
    max-height: 50vh;
    overflow-y: auto;
    display: block;

    tr {
      width: 100%;
      td {
        width: 100%;
      }
    }
  }
}
