

.notificationWrapper {
  position: relative;
  .notificationBtn {
    .badge {
      position: absolute;
      left: 8px;
      top: -5px;
      display: inline-block;
      border-radius: 50%;
      font-size: 12px;
      font-weight: normal;
      text-align: center;
      color: #fff;
      background: red;
      height: 20px;
      width: 20px;
      padding: 0;
      line-height: 20px;
    }

    &:after {
      display: none;
    }
  }

  .allRead {
    font-size: 12px;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .top-text-block {
    display: block;
    padding: 10px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: inherit !important;
    border-bottom: 1px solid #f4f4f4;
    position: relative;
    align-items: flex-start !important;

    .avatar {
      background: #FC7334;
      text-transform: uppercase;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      color: #fff;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .leftSide {
      font-size: 14px;
      flex: .9 0 0;
      a{
        text-decoration: none;
        color: inherit;
      }
    }

    .rightSide {
      text-align: right;

      button {
        border: none;
        padding: 0;
        background-color: transparent;
        pointer-events: all;
        z-index: 1;
        height: 24px;
        min-width: 24px;
        border-radius: 50%;
        position: relative;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        outline: none;
        font-size: 14px;

        .unreadIndicator {
          background-color: rgb(0, 82, 204);
          height: 8px;
          width: 8px;
          border-radius: 50%;
        }

        &:hover {
          cursor: pointer;
          background-color: rgb(223, 225, 230);
        }
      }
    }

    .top-text-light {
      color: #999;
      font-size: 0.8em;

      .taskCompletedText {
        font-size: 12px;
        color: green;
        font-weight: 500;
        margin-right: 10px;
      }
    }

    &:hover {
      background-color: rgb(244, 245, 247);

      .btn-link {
        background-color: rgb(223, 225, 230);
      }
    }

    &.unreadNotification {
      background-color: #fdf0ea;
      border: 1px solid #e9e9e9;
    }
  }


  .dropdown-menu {
    box-shadow: 0 4px 8px -2px rgba(9, 30, 66, .25), 0 0 1px rgba(9, 30, 66, .31);
    border: 0;
    border-radius: 3px;
    width: 100%;

    @media (min-width: 1024px) {
      width: 500px;
    }
    height: 400px;
    overflow: auto;
  }

  li {
    a {
      &:hover {
        color: #2780e3;

        .top-text-heading {
          text-decoration: underline;
        }

        .description {
          color: #333333;
        }
      }
    }

    &:last-child {
      .top-text-block {
        border-bottom: 0;
      }
    }

    &.emptyNotificationFlag {
      text-align: center;
      padding-top: 30px;

      div {
        i {
          font-size: 30px;
          color: #2780e3;
          transform: rotate(20deg);
        }
      }
    }
  }

  .isShow {
    position: absolute;
    right: 20px;
    padding: 0;
    display: block;
    background-color: #fff;
    margin: 0;
  }
  .hide{
    display: none;
  }

  @media (max-width: 1023px) {
    display: block;
  }
}

.d-flex{
  display: flex;
}
.justify-content-between{
  justify-content: space-between;
}
.align-items-center{
  align-items: center;
}
.text-right{
  text-align: right;
}