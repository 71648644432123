.privileges {
  .MuiAccordion-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .MuiAccordionDetails-root {
    padding: 0 16px;
  }
}
.profile_button{
  .MuiButtonBase-root{
    height: 55px;
    width: 100%;
  }

}
.order_info_button{
  .MuiButtonBase-root{
  height: 55px;
  width: 100%;
  }
}