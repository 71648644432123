.profile-name{
    margin: 0px;
    font-family: Sen;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.334;
}
.profile-info{
    margin: 0px;
    font-family: Sen;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.6;
}