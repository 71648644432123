.switch {
  box-sizing: content-box;
  display: inline-block;
  padding: 2px;
  margin: 0;
  background-clip: content-box;
  background-color: rgb(107, 119, 140);
  border-radius: 16px;
  border: 2px solid transparent;
  height: 16px;
  position: relative;
  transition: transform 0.2s ease 0s;
  width: 40px;
  color: rgb(255, 255, 255);

  &:before {
    border-radius: 50%;
    content: "";
    position: absolute;
    transform: initial;
    transition: transform 0.2s ease 0s;
    bottom: 4px;
    height: 12px;
    width: 12px;
    left: 4px;
    background-color: rgb(255, 255, 255);
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.readText {
  font-size: 12px;
  position: relative;
  top: -4px;
  font-weight: 500;
}
